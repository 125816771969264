<template>
  <section class="welcome-page">
    <h1>{{ $t('welcome') }}</h1>
    <hr>
    <p>{{ $t('mainpage') }}</p>

<div class="content-katalogi">

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_SOFAS_.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/bsg-pro-sofas2024.png" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_SOFAS_.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_TABLES_.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/bsg-pro-tabels-2024.png" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_TABLES_.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_BEDROOM_ 3.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/bsg-pro-bedroom2024.png" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_BEDROOM_ 3.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

</div>

<div class="content-katalogi">

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_CHAIRS_4.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/bsg-pro-chairs2024.png" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_CHAIRS_4.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG PRO LIGHTING.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/lighting.png" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG PRO LIGHTING.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_PRO_OUTDOOR.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/outdoor2023.jpg" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_PRO_OUTDOOR.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

</div>



<div class="content-katalogi2">

<div class="col">

<div class="" style="">

    </div>
</div>

<div class="col">

<div class="" style="">
<router-link to="https://bsg-pro.com/BSG_FABRICS_1.pdf"  target="_blank"><img style="display: block; margin: 15px auto 0px;" src="../../assets/images/bsgpro-fabrics-catalog.jpg" alt="BSG PRO catalog" width="400"></router-link>
<router-link to="https://bsg-pro.com/BSG_FABRICS_1.pdf"  target="_blank"><main-button class='filter-button' style="margin-top:15px;">{{ $t('katalog') }}</main-button></router-link>
    </div>
</div>

<div class="col">

<div class="" style="">

    </div>
</div>

</div>










    
<!--     <router-link to="/furnitures"><main-button class='allproducts'>{{ $t('allproducts') }}</main-button></router-link>  -->

<!--  wywalić v-if="false" albo zmienić na true - to wyświetli sekcję z kategoriami  -->
 <div class="categories" v-if="false">
     <product-tile v-for="category in categories" 
     :key="category.name" 
     :name="$t(category.name)" 
      :img="category.image"
      :lookfor="category.lookfor"
      ></product-tile>
</div>

  </section>
</template>

<script>
import ProductTile from '../UI/ProductTile.vue';
export default {
  data() {
    return {
      categories: [
        {
          name: "Sofas",
          image: require("../../assets/categories/sofa.png"),
          lookfor: "Sofa"
        },
        {
          name: "Armchairs",
          image: require("../../assets/categories/armchair.png"),
          lookfor: "Armchair"
        },
        {
          name: "Chaise Longues",
          image: require("../../assets/categories/chaiselongue.png"),
          lookfor: "Chaise Longue"

        },
        {
          name: "Poufs",
          image: require("../../assets/categories/pouf.png"),
          lookfor: "Pouf"
        },
        {
          name: "Chairs",
          image: require("../../assets/categories/chair.png"),
          lookfor: "Chair"
        },
        {
          name: "Tables",
          image: require("../../assets/categories/table.png"),
          lookfor: "Table"
        },
        {
          name: "Beds",
          image: require("../../assets/categories/bed.png"),
          lookfor: "Bed"
        },
        {
          name: "Mattresses",
          image: require("../../assets/categories/mattress.png"),
          lookfor: "Mattress"
        },
        {
          name: "Headboards",
          image: require("../../assets/categories/headboard.png"),
          lookfor: "Headboard"
        },
        {
          name: "Wall Pannels",
          image: require("../../assets/categories/wallpannel.png"),
          lookfor: "Wall Panels"
        },
        {
          name: "Night Tables",
          image: require("../../assets/categories/nighttable.png"),
          lookfor: "Night Table"
        },
        
      ]
    };
  },
  // emits: ['switchSelected'],
  components: {
    ProductTile,
  }
}
</script>

<style scoped>
h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

section {
  text-align: center;
  position: relative;
  top: 150px;
  width: 100%;
  margin: auto;
}

.welcome-page {
  min-height: 90vh;
}

hr {
  border-top: 1px solid black;
  opacity: 1;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.categories {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  margin-bottom: 200px;
}

.allproducts {
  margin-top: 50px;
}

h1 {
  padding-top: 10vh;
}

@media only screen and (max-width: 1100px) {
  h1 {
    padding-top: 0px;
  }
}

</style>